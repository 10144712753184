import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IotThirdPartyRoutesRoutingModule } from './iot-thirdpartyroutes-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { IotThirdpartyComponent } from './iot-thirdpartyroutes/iot-thirdparty.component';
import { SharedModule } from '../../shared/shared.module';
import { ComponentsModule } from '../../shared/components/components.module';
import { UiSwitchModule } from 'ngx-ui-switch';
import { RpCreateThirdpartyComponent } from './rp-create-thirdparty/rp-create-thirdparty.component';
@NgModule({
  declarations: [IotThirdpartyComponent, RpCreateThirdpartyComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    IotThirdPartyRoutesRoutingModule,
    BsDatepickerModule,
    GridModule,
    PDFModule,
    ExcelModule,
    TextBoxModule,
    AccordionModule,
    SharedModule,
    ComponentsModule,
    UiSwitchModule,
  ],
  // providers: [
  //   {
  //   provide: DROPZONE_CONFIG,
  //   useValue: DEFAULT_DROPZONE_CONFIG
  // }]
})
export class IotThirdPartyRoutesModule {}
