import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AppConfigData } from './../core/config/appConfigData';
import { ConfirmationModal } from './modals/common';
import { LoaderService } from '../core/services/loader.service';
import { SweetAlertIcon } from './constants/enum';
import { SortDescriptor } from '@progress/kendo-data-query';
import { process, State, toODataString } from '@progress/kendo-data-query';
import {
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';
import { LocalStorageService } from '../core/services/localStorage.service';
import { catchError } from 'rxjs/operators';
import { Validations } from './constants/validations';
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  configData: any;
  private currentUserSubject: BehaviorSubject<any>;
  public selectableSettings!: SelectableSettings;
  ServiceError = Validations.ServiceError;
  public checkboxOnly = true;
  public drag = false;
  public mode: SelectableMode = 'multiple';

  constructor(
    private http: HttpClient,
    private router: Router,
    private loaderService: LoaderService,
    private localStorageService: LocalStorageService
  ) {
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };
    const currentUser = this.getCurrentUserFromLocalStorage();
    this.currentUserSubject = new BehaviorSubject<any>(currentUser);
  }

  getCurrentUserFromLocalStorage(): any {
    const currentUserString = this.localStorageService.getItem('currentUser');
    return currentUserString ? JSON.parse(currentUserString) : null;
  }

  updateCurrentUserToken(
    newToken: string,
    refreshToken: string,
    tokenExpiresOn: string
  ): void {
    const currentUser = this.getCurrentUserFromLocalStorage();
    if (currentUser) {
      currentUser.token = newToken;
      currentUser.refreshToken = refreshToken;
      currentUser.tokenExpiresOn = tokenExpiresOn;
      this.localStorageService.setItem(
        'currentUser',
        JSON.stringify(currentUser)
      );
    }
  }

  showConfirmationDialog(modalInfo: ConfirmationModal): Promise<any> {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    return swalWithBootstrapButtons.fire({
      title: modalInfo.title,
      text: modalInfo.text,
      icon: modalInfo.icon,
      showCancelButton: modalInfo.showCancelButton,
      confirmButtonText: modalInfo.confirmButtonText,
      cancelButtonText: modalInfo.cancelButtonText,
    });
  }

  getTemplates(fileName: string): Observable<any> {
    return this.http
      .get(`${AppConfigData.getTemplatesUrl}`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        // Assuming the body is JSON
        catchError((error) => {
          console.error('getTemplates failed:', error);
          this.showMessageDialog(SweetAlertIcon.ERROR, this.ServiceError.Error, SweetAlertIcon.ERROR);
          return throwError(error);
        })
      );
  }

  getDevicesBulkUpdateTemplates(): Observable<any> {
    return this.http
      .get(`${AppConfigData.getExportDeviceUrl}`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        // Assuming the body is JSON
        catchError((error) => {
          console.error('getTemplates failed:', error);
          this.showMessageDialog(SweetAlertIcon.ERROR, this.ServiceError.Error, SweetAlertIcon.ERROR);
          return throwError(error);
        })
      );
  }

  showMessageDialog(
    title: string,
    message: string,
    icon: SweetAlertIcon
  ): void {
    Swal.fire(title, message, icon);
  }


  getAllConfigData(reload?: boolean): Observable<any> {
  
    this.loaderService.setLoading(true);
    // if (!this.configData || reload) {
    return this.http.get<any>(AppConfigData.configUrl).pipe(
      tap((data) => {
        this.configData = data;
        this.loaderService.setLoading(false);
      })
    );
    // } else {
    //   this.loaderService.setLoading(false);
    //   return of(this.configData);
    // }
  }

  // getAllConfigData(reload?: boolean): Observable<any> {
  //   this.loaderService.setLoading(true);
  //   if (!this.configData || reload) {
  //     return this.http.get<any>(AppConfigData.configUrl).pipe(
  //       tap((data) => {
  //         this.configData = data;
  //         this.loaderService.setLoading(false);
  //       })
  //     );
  //   } else {
  //     this.loaderService.setLoading(false);
  //     return of(this.configData);
  //   }
  // }
  getAllConfigDatawithGateWayProviderId(
    gatewayProviderId?: boolean
  ): Observable<any> {
    this.loaderService.setLoading(true);
    let url = `${AppConfigData.configUrl}?gatewayProviderId=${gatewayProviderId}`;
    return this.http.get<any>(url).pipe(
      tap((data) => {
        this.configData = data;
        this.loaderService.setLoading(false);
      })
    );
  }

  updateRole(role: string, instaceFlag?: boolean): void {
    const currentUserString = this.localStorageService.getItem('currentUser');
    if (currentUserString) {
      const currentUser = JSON.parse(currentUserString);
      if (currentUser.currentUserRoleDetails) {
        currentUser.currentUserRoleDetails.role = role;
        currentUser.activeInstance = instaceFlag;
        this.localStorageService.setItem(
          'currentUser',
          JSON.stringify(currentUser)
        );
        this.currentUserSubject.next(currentUser); // Update the BehaviorSubject
      }
    } else {
      console.error('currentUser not found in localStorage');
    }
  }

  getCurrentUser(): Observable<any> {
    return this.currentUserSubject.asObservable();
  }

  createOrderByQuery(sortDescriptors: SortDescriptor[]): string {
    if (!sortDescriptors || sortDescriptors.length === 0) {
      return '';
    }

    const orderByClauses = sortDescriptors.map((descriptor) => {
      // Handle cases where dir or field might be undefined
      const field = descriptor.field?.replace(/\./g, '/') ?? '';
      const dir = descriptor.dir ?? 'asc';
      return `${field} ${dir}`;
    });

    return `$orderby=${orderByClauses.join(',')}`;
  }

  createQuery(state: State): string {
    // Implement this method to create a query string from the state object
    // This should convert the state to your API's query parameters
    // Example implementation
    const queryString = `${toODataString(state)}&$count=true`;
    console.log(queryString);

    let filterQuery = '';

    const filterIndex = queryString.indexOf('$filter=');
    if (filterIndex !== -1) {
      const filterEndIndex = queryString.indexOf('&', filterIndex);
      if (filterEndIndex !== -1) {
        filterQuery = queryString.substring(filterIndex, filterEndIndex);
      } else {
        filterQuery = queryString.substring(filterIndex);
      }
    }

    console.log(filterQuery);

    return filterQuery;
  }

  bulkUpload(url: string, file: File): Observable<HttpResponse<Blob>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http
      .post(`${url}`, formData, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        catchError((error) => {
          console.error('bulkUpload failed:', error);
          this.showMessageDialog(
            'bulkUpload failed',
            this.ServiceError.Error,
            SweetAlertIcon.ERROR
          );
          return throwError(error);
        })
      );
  }
}
