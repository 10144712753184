import { Component } from '@angular/core';
import { LoaderService } from '../../../core/services/loader.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '../../../core/helpers/helper';
import { Validations } from '../../../shared/constants/validations';
import { SharedService } from '../../../shared/shared.service';
import {
  createdevice,
  resetDevicesState,
  updatedevice,
} from '../../../store/iot-devices/iot-devices.actions';
import {
  createdeviceResponse,
  updatedeviceResponse,
} from '../../../store/iot-devices/iot-devices-selector';
@Component({
  selector: 'app-iot-create-devices',
  templateUrl: './iot-create-devices.component.html',
  styleUrl: './iot-create-devices.component.scss',
})
export class IotCreateDevicesComponent {
  deviceForm!: UntypedFormGroup;
  isAllOpen: boolean = true;
  selectValue!: string[];
  configData: any;
  supplierData: any = [];
  deviceValidations = Validations.devices;
  submitted: boolean = false;
  deviceDetails: any = null;
  breadCrumbItems: Array<{}> | undefined;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private loaderService: LoaderService,
    private store: Store,
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private router: Router
  ) {
    this.getStoreData();
    this.deviceDetails = history.state?.deviceDetails
      ? JSON.parse(history.state.deviceDetails)
      : null;
    this.breadCrumbItems = [
      { label: 'Iot Devices', url: 'iotDevices', active: true },
      { label: this.deviceDetails ? 'Edit Iot Devices' : 'Create Iot Devices' },
    ];
  }

  getStoreData() {
    this.store.dispatch(resetDevicesState()); // Optionally reset state
    let createdeviceResponse$ = this.store.pipe(select(createdeviceResponse));
    let updatedeviceResponse$ = this.store.pipe(select(updatedeviceResponse));
    createdeviceResponse$.subscribe((res: any) => {
      console.log(res);

      this.loaderService.setLoading(false);
      if (res) {
        this.router.navigate(['/iotDevices']);
      }
    });

    updatedeviceResponse$.subscribe((res: any) => {
      console.log(res);

      this.loaderService.setLoading(false);
      if (res) {
        this.router.navigate(['/iotDevices']);
      }
    });
  }

  ngOnInit(): void {




    this.getAllConfigData();

    this.deviceForm = this.formBuilder.group({
      deviceSRNo: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20),
        ],
      ],
      macAddress: ['', [Validators.required]],
      masterId: ['', [Validators.required]],
      supplierId: [null, [Validators.required]],
      projectId: [null, [Validators.required]],
      yearOfMake: ['', [Validators.required]],
      responseConfigurationId: [null, [Validators.required]],
      isMonitoringDevice: [false, [Validators.required]],
      isWiredCommunicationDevice: [false],
      gatewayInformationId: [null,],
      meterReading: [0],
      meterReadingEB: [0],
      meterReadingDG: [0],
      // deviceId:[null,  [  Validators.pattern(/^\d+$/) ]],
      deviceId: [
        '',
        [
          // Validators.maxLength(10),
          Validators.pattern(/^\d+$/),

        ],
      ],
    });

    if (this.deviceDetails) {
      const year = parseInt(this.deviceDetails.yearOfMake, 10);
      const yearDate = new Date(year, 0, 1);
      this.deviceForm.patchValue({
        deviceSRNo: this.deviceDetails.deviceSRNo,
        macAddress: this.deviceDetails.macAddress,
        masterId: this.deviceDetails.category.id,
        supplierId: this.deviceDetails.supplier.id,
        projectId: this.deviceDetails.projectId,
        yearOfMake: yearDate,
        responseConfigurationId: this.deviceDetails.responseConfiguration.id,
        isMonitoringDevice: this.deviceDetails.isMonitoringDevice,
        isWiredCommunicationDevice: this.deviceDetails.isWiredCommunicationDevice,
        gatewayInformationId: this.deviceDetails.gatewayInformationId,
        meterReading: this.deviceDetails?.meterReading || 0,
        meterReadingEB: this.deviceDetails?.meterReadingEB || 0,
        meterReadingDG: this.deviceDetails?.meterReadingDG || 0,
        deviceId: this.deviceDetails?.deviceId || null

      });

      let utility = { id: this.deviceDetails?.category?.id };
      // this.onUtility(utility);

      if (this.deviceDetails.isWiredCommunicationDevice) {
        this.deviceForm.get('gatewayInformationId')?.setValidators([Validators.required]);
        this.deviceForm.get('deviceId')?.setValidators([Validators.required]);
        this.deviceForm.get('deviceId')?.setValidators([Validators.required, Validators.pattern(/^\d+$/),]);



      } else {
        this.deviceForm.get('gatewayInformationId')?.clearValidators();
        this.deviceForm.get('deviceId')?.clearValidators();
      }
      this.deviceForm.get('gatewayInformationId')?.updateValueAndValidity();
      this.deviceForm.get('deviceId')?.updateValueAndValidity();
// alert("hgf"+this.deviceForm.get('masterId')?.value)

    

      // alert(this.configData.utilities.find((item:any) => item.id ===  this.deviceForm.get('masterId')?.value)?.name)

    }

    // this.deviceForm.get('masterId')?.valueChanges.subscribe((value) => {
    //   alert()
    //   this.updateValidations();
    // });
  }

  getUtilityName() {
    return this.configData.utilities.find((item: any) => item.id === this.deviceForm.get('masterId')?.value)?.name
  }
  getAllConfigData(): void {
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (
        data.statusCode == 200 &&
        data.message == 'Config data retrieved successfully.'
      ) {
        this.configData = data.data;
        let utility = { id: this.deviceDetails?.category?.id };
        // this.onUtility(utility);
        if (this.deviceForm.get('masterId')?.value) {
          if (this.getUtilityName() == 'Electricity') {
            this.deviceForm.get('meterReadingDG')?.setValidators([Validators.required]);
            this.deviceForm.get('meterReadingEB')?.setValidators([Validators.required]);
            this.deviceForm.get('meterReading')?.clearValidators();
  
          } else {
            this.deviceForm.get('meterReadingDG')?.clearValidators();
            this.deviceForm.get('meterReadingEB')?.clearValidators();
            this.deviceForm.get('meterReading')?.setValidators([Validators.required]);
          }
  
  
          this.deviceForm.get('meterReadingDG')?.updateValueAndValidity();
          this.deviceForm.get('meterReadingEB')?.updateValueAndValidity();
          this.deviceForm.get('meterReading')?.updateValueAndValidity();
  
  
  
        } else {
          this.deviceForm.get('meterReadingDG')?.clearValidators();
          this.deviceForm.get('meterReadingEB')?.clearValidators();
          this.deviceForm.get('meterReading')?.clearValidators();
          this.deviceForm.get('meterReadingDG')?.updateValueAndValidity();
          this.deviceForm.get('meterReadingEB')?.updateValueAndValidity();
          this.deviceForm.get('meterReading')?.updateValueAndValidity();
        }
      }
    });
  }


  updateValidations() {
    if (this.getUtilityName()) {
      if (this.getUtilityName() == 'Electricity') {
        this.deviceForm.get('meterReadingDG')?.setValidators([Validators.required]);
        this.deviceForm.get('meterReadingEB')?.setValidators([Validators.required]);
        this.deviceForm.get('meterReading')?.clearValidators();

      } else {
        this.deviceForm.get('meterReadingDG')?.clearValidators();
        this.deviceForm.get('meterReadingEB')?.clearValidators();
        this.deviceForm.get('meterReading')?.setValidators([Validators.required]);
      }


      this.deviceForm.get('meterReadingDG')?.updateValueAndValidity();
      this.deviceForm.get('meterReadingEB')?.updateValueAndValidity();
      this.deviceForm.get('meterReading')?.updateValueAndValidity();



    } else {
      this.deviceForm.get('meterReadingDG')?.clearValidators();
      this.deviceForm.get('meterReadingEB')?.clearValidators();
      this.deviceForm.get('meterReading')?.clearValidators();

      this.deviceForm.get('meterReadingDG')?.updateValueAndValidity();
      this.deviceForm.get('meterReadingEB')?.updateValueAndValidity();
      this.deviceForm.get('meterReading')?.updateValueAndValidity();
    }
  }

  onUtility(utility: any) {
    console.log(utility);
    this.configData;
    this.supplierData = this.configData?.suppliers.filter(
      (item: any) => item.master.id === utility.id
    );
    if (this.deviceDetails) {
      this.deviceForm.patchValue({
        supplierId: this.deviceDetails?.supplier?.id,
      });
    }
  }
  onDateChange(date: any): void {
    const selectedDate = new Date(date);
    const year = selectedDate.getFullYear();

    // Update the form control to only store the year
    this.deviceForm.get('date_registered')?.setValue(year.toString());
  }

  onYearChange(date: Date): void {
    // Extract the year from the selected date and update the form control
    // const year = date.getFullYear();
    // date
    //   ? this.deviceForm.get('yearOfMake')?.setValue(date)
    //   : this.deviceForm.get('yearOfMake')?.setValue(null);
  }

  onSwitchChange(event: boolean): void {
    console.log('Switch value changed:', event);
    // Perform actions based on the new value
    if (event) {
      this.deviceForm.get('gatewayInformationId')?.setValidators([Validators.required]);
    } else {
      this.deviceForm.get('gatewayInformationId')?.clearValidators();
    }
    this.deviceForm.get('gatewayInformationId')?.updateValueAndValidity();
  }

  saveDevice(type: string) {
    this.submitted = true;
    if (this.deviceForm.valid) {
      this.submitted = false;
      // Handle form submission

      console.log(this.deviceForm.value);
      if (type == 'Save') {
        this.store.dispatch(
          createdevice({
            deviceData: this.deviceForm.value,
          })
        );
      } else {

        const payload = {
          deviceSRNo: this.deviceForm.get('deviceSRNo')?.value,
          macAddress: this.deviceForm.get('macAddress')?.value,
          masterId: this.deviceForm.get('masterId')?.value,
          supplierId: this.deviceForm.get('supplierId')?.value,
          projectId: this.deviceForm.get('projectId')?.value,
          yearOfMake: this.deviceForm.get('yearOfMake')?.value,
          responseConfigurationId: this.deviceForm.get('responseConfigurationId')?.value,
          isMonitoringDevice: this.deviceForm.get('isMonitoringDevice')?.value,
          isWiredCommunicationDevice: this.deviceForm.get('isWiredCommunicationDevice')?.value,
          gatewayInformationId: this.deviceForm.get('gatewayInformationId')?.value,
          meterReading: this.deviceForm.get('meterReading')?.value || 0,
          meterReadingEB: this.deviceForm.get('meterReadingEB')?.value || 0,
          meterReadingDG: this.deviceForm.get('meterReadingDG')?.value || 0,
          lastMeterReadingUpdatedDate: this.deviceDetails?.lastMeterReadingUpdatedDate,
          deviceId: this.deviceForm.get('deviceId')?.value
        };

        console.log(payload);


        this.store.dispatch(
          updatedevice({
            deviceData: payload,
            id: this.deviceDetails.id,
          })
        );
      }
    }
  }
}
