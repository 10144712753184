import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IotReadingRoutingModule } from './iot-reading-routing.module';
import { IotReadingComponent } from './iot-reading/iot-reading.component';
import { NgStepperModule } from 'angular-ng-stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { SharedModule } from '../../shared/shared.module';

// import { DropzoneModule } from 'ngx-dropzone-wrapper';
// import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
// import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ComponentsModule } from '../../shared/components/components.module';
import { ViewDetailsComponent } from './view-details/view-details.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
// const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
//   // Change this to your upload POST address:
//   url: '',
//   maxFilesize: 50,
//   acceptedFiles: 'image/*'
// };
import { FilterMenuModule } from '@progress/kendo-angular-grid'; // Make sure to include this

@NgModule({
  declarations: [IotReadingComponent, ViewDetailsComponent],
  imports: [
    CommonModule,
    NgStepperModule,
    IotReadingRoutingModule,
    CdkStepperModule,
    GridModule,
    PDFModule,
    ExcelModule,
    TextBoxModule,
    SharedModule,
    ComponentsModule,
    BsDatepickerModule,FilterMenuModule
    // DropzoneModule
  ],
  // providers: [
  //   {
  //   provide: DROPZONE_CONFIG,
  //   useValue: DEFAULT_DROPZONE_CONFIG
  // }]
})
export class IotReadingModule {}
