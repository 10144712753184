import { createAction, props } from '@ngrx/store';
import { thirdpartyapiModel } from './thirdpartyapis.model';

//fetch
export const getthirdpartyapiList = createAction(
  '[thirdpartyapi],getthirdpartyapi',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getthirdpartyapiListSucess = createAction(
  '[thirdpartyapi],fetch getthirdpartyapi success',
  props<{ getthirdpartyapi_Response: any }>()
);
export const getthirdpartyapiListFailure = createAction(
  '[thirdpartyapi],etch getthirdpartyapi failed',
  props<{ getthirdpartyapiError: any }>()
);

//get All thirdpartyapi Export
export const getthirdpartyapiExport = createAction(
  '[thirdpartyapi],getthirdpartyapi Export',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    id: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getthirdpartyapiExportSucess = createAction(
  '[thirdpartyapi],fetch getthirdpartyapi  Export success',
  props<{ getthirdpartyapiExportResponse: any }>()
);
export const getthirdpartyapiExportFailure = createAction(
  '[thirdpartyapi],etch getthirdpartyapi Export failed',
  props<{ getthirdpartyapiExportError: any }>()
);

export const getAllthirdpartyapiList = createAction(
  '[thirdpartyapi],getAllthirdpartyapi',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    id: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAllthirdpartyapiListSucess = createAction(
  '[thirdpartyapi],fetch getAllthirdpartyapi success',
  props<{ getAllthirdpartyapi_Response: any }>()
);
export const getAllthirdpartyapiListFailure = createAction(
  '[thirdpartyapi],etch getAllthirdpartyapi failed',
  props<{ getAllthirdpartyapiError: any }>()
);

//Create thirdpartyapi :
export const createthirdpartyapi = createAction(
  '[thirdpartyapi] Creategetthirdpartyapir',
  props<{ thirdparty: thirdpartyapiModel }>()
);
export const createthirdpartyapiSuccess = createAction(
  '[thirdpartyapi] getthirdpartyapi Success',
  props<{ createthirdpartyapi_Response: any }>()
);
export const createthirdpartyapiFailure = createAction(
  '[thirdpartyapi] getthirdpartyapi Failure',
  props<{ createthirdpartyapiError: any }>()
);

// Update Data
export const updatethirdpartyapi = createAction(
  '[thirdpartyapi] Update thirdpartyapi',
  props<{ updatedData: thirdpartyapiModel }>()
);
export const updatethirdpartyapiSuccess = createAction(
  '[thirdpartyapi] Update thirdpartyapi Success',
  props<{ updatethirdpartyapiResponse: any }>()
);
export const updatethirdpartyapiFailure = createAction(
  '[thirdpartyapi] Update thirdpartyapi Failure',
  props<{ updatethirdpartyapiError: any }>()
);

// Delete Data
export const deletethirdpartyapi = createAction(
  '[thirdpartyapi] Delete thirdpartyapi',
  props<{ id: string }>()
);
export const deletethirdpartyapiSuccess = createAction(
  '[thirdpartyapi] Delete thirdpartyapi Success',
  props<{ deletethirdpartyapiResponse: any }>()
);
export const deletethirdpartyapiFailure = createAction(
  '[thirdpartyapi] Delete thirdpartyapi Failure',
  props<{ deletethirdpartyapiError: any }>()
);

export const SetthirdpartyapiStatus = createAction(
  '[thirdpartyapi] SetthirdpartyapiStatus',
  props<{ request: any }>()
);

export const resetthirdpartyapisState = createAction('[resetthirdpartyapisState ] resetthirdpartyapisState State');