<div class="container-fluid">
  <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
  <!-- <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-0 font-size-18">IOT DEVICES</h4>
            </div>
        </div>
    </div> -->

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <section>
            <div class="row mb-2 d-sm-block d-md-none">
              <h4 class="card-title mb-4 text-decoration-underline">Gateway Information</h4>
            </div>

            <ng-template #template let-anchor>
              <span>{{ anchor.nativeElement.innerText }}</span>
            </ng-template>
            <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
              (mouseover)="showTooltip($event)">
              <kendo-grid [data]="thirdpartyGridData" [pageSize]="pageSize" [skip]="skip" [sortable]="true" [pageable]="{
                        buttonCount: buttonCount,
                        info: info,
                        type: type,
                        pageSizes: pageSizes,
                        previousNext: previousNext
                      }" [reorderable]="true" filterable="menu" [filter]="thirdpartyApisState.filter"
                [skip]="thirdpartyApisState.skip" (filterChange)="onThirdPArtyFilterChange($event)"
                (sortChange)="onThirdPartySortChange($event)" [sort]="thirdpartyApisState.sort"
                [columnMenu]="{ filter: true }" [resizable]="true" scrollable="scrollable"
                [selectable]="selectableSettings" (pageChange)="thirdpartyPageChange($event)"
                (dataStateChange)="dataStateChange($event)" (selectionChange)="thirdpartyOnSelect($event)">


                <ng-template kendoGridToolbarTemplate>

                  <div class="search_icon">
                    <kendo-textbox [style.width.px]="250" [style.height.px]="35" [(ngModel)]="thirdpartySearch"
                      [clearButton]="true" (valueChange)="clearSearchValueChange($event)"
                      placeholder="Search in all columns...">
                    </kendo-textbox>

                    <button class="btn btn-primary btn-md border-left-none" (click)="onThirdpartyFilter($event)">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                  <kendo-grid-spacer></kendo-grid-spacer>

                  <button class="btn btn-outline-primary btn-sm me-2" (click)="addNewThirdparty()">Add New
                  </button>
                  <button class="btn btn-outline-primary btn-sm d-flex align-items-center" (click)="onExport('Excel')">
                    <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                  </button>


                  <!-- <button kendoGridPDFCommand class="btn btn-light" (click)="gatewayonExport()">
                              <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->

                </ng-template>
                <!-- Excel Export -->

                <!-- Pdf   start-->
                <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                  <kendo-grid [kendoGridBinding]="selectedRowdata.length>0 ? selectedRowdata:thirdpartyGridData.data"
                    #pdfGrid>

                    <!-- Endpoint Name -->
                    <kendo-grid-column field="endpointName" title="Endpoint Name"></kendo-grid-column>

                    <!-- URL -->
                    <kendo-grid-column field="url" title="URL"></kendo-grid-column>

                    <!-- Description -->
                    <kendo-grid-column field="description" title="Description"></kendo-grid-column>

                    <!-- Project Name -->
                    <kendo-grid-column field="projectName" title="Project Name"></kendo-grid-column>

                    <kendo-grid-pdf fileName="thirdpartyApi.pdf" [allPages]="true" paperSize="A4"
                      [landscape]="true"></kendo-grid-pdf>
                    <kendo-grid-excel fileName="thirdpartyApi.xlsx"></kendo-grid-excel>

                  </kendo-grid>
                </div>

                <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                  [width]="40"></kendo-grid-checkbox-column>


                <!-- Endpoint Name -->
                <kendo-grid-column field="endpointName" title="Endpoint Name"></kendo-grid-column>

                <!-- URL -->
                <kendo-grid-column field="url" title="URL"></kendo-grid-column>

                <!-- Description -->
                <kendo-grid-column field="description" title="Description"></kendo-grid-column>

                <!-- Project Name -->
                <kendo-grid-column field="projectName" title="Project Name"></kendo-grid-column>



                




                <kendo-grid-command-column [columnMenu]="false" [width]="150">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="d-flex">
                      <!-- Edit Button -->
                      <button kendoTooltip title="Go to ThirdPArty Api Edit" 
                        class="btn btn-primary btn-sm d-flex align-items-center"
                        (click)="editThirdPArty(dataItem)">
                        <i class="fas fa-edit"></i>
                      </button>
                
                    </div>
                  </ng-template>
                </kendo-grid-command-column>
                

              </kendo-grid>
            </div>

          </section>
        </div>
      </div>
    </div>
  </div>

</div>