import { createFeatureSelector, createSelector } from '@ngrx/store';
import { thirdpartyapisState } from './thirdpartyapis.reducer';

export const getthirdpartyapiState = createFeatureSelector<thirdpartyapisState>('thirdpartyapi');

export const getthirdpartyapiResponse = createSelector(
  getthirdpartyapiState,
  (state: thirdpartyapisState) => state.getthirdpartyapi_Response
);

export const getthirdpartyapiListSucess = createSelector(
  getthirdpartyapiState,
  (state: thirdpartyapisState) => state.getthirdpartyapiListSucess
);

export const createthirdpartyapiResponse = createSelector(
  getthirdpartyapiState,
  (state: thirdpartyapisState) => state.createthirdpartyapi_Response
);

export const deletethirdpartyapiResponse = createSelector(
  getthirdpartyapiState,
  (state: thirdpartyapisState) => state.deletethirdpartyapiResponse
);

export const updatethirdpartyapiResponse = createSelector(
  getthirdpartyapiState,
  (state: thirdpartyapisState) => state.updatethirdpartyapiResponse
);
export const getAllthirdpartyapi_Response = createSelector(
  getthirdpartyapiState,
  (state: thirdpartyapisState) => state.getAllthirdpartyapi_Response
);


export const getthirdpartyapiExportResponse = createSelector(
  getthirdpartyapiState,
  (state: thirdpartyapisState) => state.getthirdpartyapiExportResponse
);

/// 