// date-helper.ts
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';

export class Helper {
  static formatDate(isoString: any): string {
    const date = new Date(isoString);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  static excelformatDate(dateTime: string | Date | null | undefined): string {
    if (!dateTime) {
      return '-'; // Return '-' if the dateTime is not available
    }
    
    const date = new Date(dateTime); // Convert to Date object
    
    if (isNaN(date.getTime())) {
      return '-'; // Return '-' if the date is invalid
    }
    
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    
    return date.toLocaleDateString('en-GB', options);
  }
  

  static noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    };
  }

  // static PatternValidator(pattern: any): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: any } | null => {
  //     //   const pattern: RegExp = /^[a-zA-Z0-9\-_&]*$/; // Alphanumeric and (-, _, &) characters allowed
  //     if (!pattern.test(control.value)) {
  //       return { pattern: true };
  //     }
  //     return null;
  //   };
  // }

  static PatternValidator(pattern: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // Ensure that the control's value exists before applying the regex test
      if (control.value && !pattern.test(control.value)) {
        return { pattern: true }; // Invalid if the pattern does not match
      }
      return null; // Valid if the pattern matches
    };
  }
  

  static numberWithDecimalsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const pattern = /^\d+(\.\d{1,2})?$/; // Allow numbers with up to 2 decimal places
      if (control.value && !pattern.test(control.value)) {
        return { numberWithDecimals: true };
      }
      return null;
    };
  }
}
