import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CrudService } from '../../core/services/crud.service';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { map, catchError, exhaustMap, finalize } from 'rxjs/operators';
import { SweetAlertIcon } from '../../shared/constants/enum';
import { Validations } from '../../shared/constants/validations';
import { LoaderService } from '../../core/services/loader.service';
import {
  getthirdpartyapiList,
  getthirdpartyapiListSucess,
  getthirdpartyapiListFailure,
  createthirdpartyapi,
  createthirdpartyapiSuccess,
  createthirdpartyapiFailure,
  updatethirdpartyapi,
  updatethirdpartyapiFailure,
  updatethirdpartyapiSuccess,
  deletethirdpartyapi,
  deletethirdpartyapiSuccess,
  deletethirdpartyapiFailure,
  SetthirdpartyapiStatus,
  getAllthirdpartyapiListFailure,
  getAllthirdpartyapiListSucess,
  getAllthirdpartyapiList,
  getthirdpartyapiExport,
  getthirdpartyapiExportSucess,
  getthirdpartyapiExportFailure,
} from './thirdpartyapis.action';
import { ThirdpartyapiService } from '../../core/services/thirdpartyapi.service';

@Injectable()
export class thirdpartyapisEffect {
  ServiceError = Validations.ServiceError;

  createthirdpartyapi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createthirdpartyapi),
      exhaustMap((thirdpartyapiData) => {
        console.log(thirdpartyapiData);
        this.loaderService.setLoading(true);
        return this.thirdpartyapiService.createthirdpartyapi(thirdpartyapiData.thirdparty).pipe(
          map((response: any) => {
            console.log(response);
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 201) {
              this.sharedService.showMessageDialog(
                'Success',
                'Gatway added successfully',
                SweetAlertIcon.SUCCESS
              );
              // this.router.navigate(['/users']);
              return createthirdpartyapiSuccess({ createthirdpartyapi_Response: response });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return createthirdpartyapiFailure({ createthirdpartyapiError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(createthirdpartyapiFailure({ createthirdpartyapiError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  getthirdpartyapiList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getthirdpartyapiList),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        // if (!searchTerm) {
        this.loaderService.setLoading(true);
        // }
        return this.thirdpartyapiService
          .getthirdpartyapi(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getthirdpartyapiListSucess({
                  getthirdpartyapi_Response: response.data,
                });
              } else {
                return getthirdpartyapiListFailure({ getthirdpartyapiError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getthirdpartyapiListFailure({ getthirdpartyapiError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getthirdpartyapiExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getthirdpartyapiExport),
      exhaustMap(({ pageIndex, pageSize, searchTerm, id, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.thirdpartyapiService
          .getthirdpartyapi(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getthirdpartyapiExportSucess({
                  getthirdpartyapiExportResponse: response.data,
                });
              } else {
                return getthirdpartyapiExportFailure({
                  getthirdpartyapiExportError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getthirdpartyapiExportFailure({ getthirdpartyapiExportError: error })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getAllthirdpartyapiList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllthirdpartyapiList),
      exhaustMap(({ pageIndex, pageSize, searchTerm, id, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.thirdpartyapiService
          .getthirdpartyapi(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getAllthirdpartyapiListSucess({
                  getAllthirdpartyapi_Response: response.data,
                });
              } else {
                return getAllthirdpartyapiListFailure({
                  getAllthirdpartyapiError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getAllthirdpartyapiListFailure({ getAllthirdpartyapiError: error })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  updatethirdpartyapi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatethirdpartyapi),
      exhaustMap((updatedData) => {
        console.log(updatedData, '-----');
        this.loaderService.setLoading(true);
        return this.thirdpartyapiService.updatethirdpartyapi(updatedData.updatedData).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 201) {
              this.sharedService.showMessageDialog(
                'Success',
               ' Gatway Updated successfully',
                SweetAlertIcon.SUCCESS
              );
              return updatethirdpartyapiSuccess({ updatethirdpartyapiResponse: response });
              //this.router.navigate(['/users']);
              // return getuserlist({ pageIndex: 1, pageSize: 10, searchTerm: '', criteria: "" })
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return updatethirdpartyapiFailure({ updatethirdpartyapiError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(updatethirdpartyapiFailure({ updatethirdpartyapiError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );



 

  constructor(
    private actions$: Actions,
    private CrudService: CrudService,
    private thirdpartyapiService: ThirdpartyapiService,
    private router: Router,
    private loaderService: LoaderService,
    private sharedService: SharedService
  ) {}
}
