import { Action, createReducer, on } from '@ngrx/store';
import { thirdpartyapiModel } from './thirdpartyapis.model';
import {
  getthirdpartyapiList,
  getthirdpartyapiListSucess,
  getthirdpartyapiListFailure,
  updatethirdpartyapiSuccess,
  createthirdpartyapi,
  createthirdpartyapiSuccess,
  createthirdpartyapiFailure,
  deletethirdpartyapiSuccess,
  getAllthirdpartyapiList,
  getAllthirdpartyapiListSucess,
  getAllthirdpartyapiListFailure,
  getthirdpartyapiExport,
  getthirdpartyapiExportSucess,
  getthirdpartyapiExportFailure,
  resetthirdpartyapisState,
} from './thirdpartyapis.action';

export interface thirdpartyapisState {
  geatwaylist: thirdpartyapiModel | null;
  createthirdpartyapiError: any;
  createthirdpartyapi_Response: any;
  getthirdpartyapi_Response: any;
  deletethirdpartyapiResponse: any;
  getthirdpartyapiListSucess: any;
  updatethirdpartyapiResponse: any;
  getAllthirdpartyapi_Response: any;
  getthirdpartyapiExportResponse: any
}

export const initialState: thirdpartyapisState = {
  geatwaylist: null,
  createthirdpartyapiError: null,
  createthirdpartyapi_Response: null,
  getthirdpartyapi_Response: null,
  deletethirdpartyapiResponse: null,
  getthirdpartyapiListSucess: null,
  updatethirdpartyapiResponse: null,
  getAllthirdpartyapi_Response: null,
  getthirdpartyapiExportResponse: null
};

export const thirdpartyapisReducer = createReducer(
  initialState,
  on(getthirdpartyapiList, (state) => ({ ...state })),
  on(createthirdpartyapi, (state) => ({ ...state, error: null })),
  on(createthirdpartyapiSuccess, (state, { createthirdpartyapi_Response }) => ({
    ...state,
    createthirdpartyapi_Response,
  })),
  on(createthirdpartyapiFailure, (state, { createthirdpartyapiError }) => ({
    ...state,
    createthirdpartyapiError,
  })),
  on(getthirdpartyapiListSucess, (state, { getthirdpartyapi_Response }) => ({
    ...state,
    getthirdpartyapi_Response,
  })),
  on(getthirdpartyapiListFailure, (state, { getthirdpartyapiError }) => ({
    ...state,
    getthirdpartyapiError,
  })),
  on(deletethirdpartyapiSuccess, (state, { deletethirdpartyapiResponse }) => ({
    ...state,
    deletethirdpartyapiResponse,
  })),
  on(updatethirdpartyapiSuccess, (state, { updatethirdpartyapiResponse }) => ({
    ...state,
    updatethirdpartyapiResponse,
  })),
  on(getAllthirdpartyapiList, (state) => ({ ...state })),
  on(getAllthirdpartyapiListSucess, (state, { getAllthirdpartyapi_Response }) => ({
    ...state,
    getAllthirdpartyapi_Response,
  })),
  on(getAllthirdpartyapiListFailure, (state, { getAllthirdpartyapiError }) => ({
    ...state,
    getAllthirdpartyapiError,
  })),


  on(getthirdpartyapiExport, (state) => ({ ...state })),
  on(getthirdpartyapiExportSucess, (state, { getthirdpartyapiExportResponse }) => ({
    ...state,
    getthirdpartyapiExportResponse,
  })),
  on(getthirdpartyapiExportFailure, (state, { getthirdpartyapiExportError }) => ({
    ...state,
    getthirdpartyapiExportError,
  })),
  on(resetthirdpartyapisState, (state) => {
    console.log('resetthirdpartyapisState action triggered'); // Log to console
    return {
      ...state,
      getthirdpartyapi_Response: null, // Reset the `getthirdpartyapi_Response` property
    };
  })

);
