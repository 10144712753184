<!-- Add Gateways modal -->

<!-- <div class="modal-dialog modal-dialog-centered"> -->
    <div class="modal-content">
        <div class="modal-header bg-primary text-white">
            <h5 class="modal-title"> {{ thirdpartyUpdateData?.id? 'Update Billing urls' : 'Add Billing urls' }} </h5>
            <button type="button" (click)="dismissModal()" class="btn-close text-white" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form class="form-horizontal" [formGroup]="creategatewayForm">
             
                <div class="mb-3">
                    <label>Project Name <span class="required">*</span></label>
                    <ng-select [items]="listofProjects" formControlName="projectId" (change)="valueChange($event)" [multiple]="false" bindLabel="name"
                        bindValue="id" [placeholder]="'Select...'"></ng-select>
                        @if(submitted && gatewayControls['projectId'].errors){
                            <div class="invalid-feedback">
                                @if(gatewayControls['projectId'].errors['required']){
                                <div>{{fileValidations.project.required}}</div>}                         
                              
                            </div>}
                    
                </div>
    
    
                <div class="mb-3">
                    <label>End Point Name <span class="required">*</span></label>
                    <input type="text" class="form-control" formControlName="endpointName" id="endpointName" />
                    @if(submitted && gatewayControls['endpointName'].errors){
                        <div class="invalid-feedback">
                            @if(gatewayControls['endpointName'].errors['required']){
                            <div>{{fileValidations.endpointName.required}}</div>}
                          
                        </div>}
    
    
                </div>
    
    
                <div class="mb-3">
                    <label>Url <span class="required">*</span></label>
                    <input type="text" class="form-control" formControlName="url" id="url" />
                    @if(submitted && gatewayControls['url'].errors){
                        <div class="invalid-feedback">
                            @if(gatewayControls['url'].errors['required']){
                            <div>{{fileValidations.url.required}}</div>}
                        </div>}
                  
                </div>
                <div class="mb-3">
                    <label>Description</label>
                    <input type="text" class="form-control" formControlName="description" id="description" />
                  
                </div>
    
                
              
    
            </form>
        </div>
        <div class="modal-footer">
            @if(thirdpartyUpdateData?.id){
            <button type="button" class="btn btn-primary waves-effect waves-light"
                (click)="saveThirdParty('Update')">Update</button>}
            @else{
            <button type="button" class="btn btn-primary waves-effect waves-light" (click)="saveThirdParty('Create')">Save
                Billing urls</button>}
    
            <!-- Add Gateways modal End  -->
        </div>
    </div>
    <!-- </div> -->