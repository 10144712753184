import { MastersModuleEffects } from './store/Masters/masters.effects';
import { NgModule } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutsModule } from './layouts/layouts.module';
import { CoreModule } from './core/core.module';
import { FeaturesModule } from './features/features.module';
import { SharedModule } from './shared/shared.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { rootReducer } from './store';

import { AuthenticationEffects } from './store/Authentication/authentication.effects';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat'; // Import AngularFireModule
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { FileUploadInterceptor } from './core/interceptors/fileprogress.interceptor';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ExcelModule } from '@progress/kendo-angular-grid';
import { SuppliersModuleEffects } from './store/Suppliers/suppliers.effects';
import { PayloadConfigsModuleEffects } from './store/payload_Config/payloadConfig.effects';
import { DevicesModuleEffects } from './store/iot-devices/iot-devices.effects';
import { ReadingsModuleEffects } from './store/iot-readings/iot-readings.effects';
import { gatewayEffect } from './store/GateWay/gateway.effect';
import { thirdpartyapisEffect } from './store/thirdpartyapis/thirdpartyapis.effect';

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutsModule,
    PDFExportModule,
    CoreModule,
    HttpClientModule,
    FeaturesModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    SharedModule.forRoot(),
    EffectsModule.forRoot([]),
    StoreModule.forRoot(rootReducer),
    EffectsModule.forRoot([
      AuthenticationEffects,
      MastersModuleEffects,
      SuppliersModuleEffects,
      PayloadConfigsModuleEffects,
      DevicesModuleEffects,
      ReadingsModuleEffects,
      gatewayEffect,
      thirdpartyapisEffect,
    ]),
    AngularFireModule.initializeApp(environment.firebaseConfig), // Initialize AngularFireModule with your Firebase configuration
    AngularFireMessagingModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    // NgbModule
    PDFExportModule,
    ExcelModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FileUploadInterceptor,
      multi: true,
    },
    provideClientHydration(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
