import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { catchError, map } from 'rxjs/operators';
import { AppConfigData } from '../config/appConfigData';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { thirdpartyapiModel } from '../../store/thirdpartyapis/thirdpartyapis.model';

@Injectable({
  providedIn: 'root',
})
export class ThirdpartyapiService {
  constructor(private http: HttpClient, private router: Router) {}

  getthirdpartyapi(
    pageIndex: number = 1,
    pageSize: number | undefined,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let url =
      `${AppConfigData.getThirdPartyUrl}?pageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  //Create  thirdpartyapi
  createthirdpartyapi(thirdpartyapiData: any): Observable<any> {
    console.log('userData->', thirdpartyapiData);

    return this.http.post<any>(AppConfigData.createThirdPartyUrl, thirdpartyapiData).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('thirdpartyapiDataCreation failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  updatethirdpartyapi(thirdpartyapiData: thirdpartyapiModel): Observable<any> {
    return this.http
      .post<any>(AppConfigData.updateTrhirdPartyUrl + thirdpartyapiData.id, thirdpartyapiData)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('getuserprofile failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }


}
