import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IotThirdpartyComponent } from './iot-thirdpartyroutes/iot-thirdparty.component';
import { RpCreateThirdpartyComponent } from './rp-create-thirdparty/rp-create-thirdparty.component';

const routes: Routes = [
  { path: 'billingurls', component: IotThirdpartyComponent },
  { path: 'billingurls/createbillingurls', component: RpCreateThirdpartyComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IotThirdPartyRoutesRoutingModule {}
